import React from "react";
import Header from "../../components/header/Header";
import Slider from "../../components/slider/SliderThreeAnimation";
import About from "../../components/about/AboutLightAnimation";
import Service from "../../components/service/ServiceTwoAnimation";
import Portfolio from "../../components/portfolio/PortfolioAnimation";
import Contact from "../../components/contact/ContactAnimation";
import Footer from "../../components/footer/FooterAnimation";
import useDocumentTitle from "../../components/useDocumentTitle";

const HomeThree = () => {
  useDocumentTitle("Home Light Animation || React Personal Portfolio Template");
  return (
    <>
      <Header />
      {/* End Header Section */}

      <Slider />
      {/* End Banner Section */}

      {/*/!* Services *!/*/}
      {/*<section id="services" className="section services-section gray-bg">*/}
      {/*  <div className="container">*/}
      {/*    <div className="row justify-content-center section-title text-center">*/}
      {/*      <div className="col-lg-7">*/}
      {/*        <h3 className="font-alt">My Services</h3>*/}
      {/*        <p>*/}
      {/*          I design and develop services for customers of all sizes,*/}
      {/*          specializing in creating stylish, modern websites, web services*/}
      {/*          and online stores.*/}
      {/*        </p>*/}
      {/*      </div>*/}
      {/*    </div>*/}
      {/*    /!* End .row *!/*/}
      {/*    <Service />*/}
      {/*  </div>*/}
      {/*</section>*/}
      {/*/!* End Services *!/*/}

      {/*<section id="work" className="section gray-bg">*/}
      {/*  <div className="container">*/}
      {/*    <div className="row justify-content-center section-title text-center">*/}
      {/*      <div className="col-lg-7">*/}
      {/*        <h3 className="font-alt">My Portfolio</h3>*/}
      {/*        <p>*/}
      {/*          I design and develop services for customers of all sizes,*/}
      {/*          specializing in creating stylish, modern websites, web services*/}
      {/*          and online stores.*/}
      {/*        </p>*/}
      {/*      </div>*/}
      {/*    </div>*/}
      {/*    /!* End .row *!/*/}
      {/*    <Portfolio />*/}
      {/*  </div>*/}
      {/*</section>*/}
      {/*/!* End Portfolio *!/*/}

      {/*/!* About Me *!/*/}
      {/*<section id="about" className="section about-section after-left-section">*/}
      {/*  <div className="container">*/}
      {/*    <About />*/}
      {/*  </div>*/}
      {/*</section>*/}
      {/*/!* End About Me *!/*/}

      {/*/!* Contact *!/*/}
      {/*<section id="contact" className="section after-left-section">*/}
      {/*  <div className="container">*/}
      {/*    <div className="row align-items-center">*/}
      {/*      <div className="col-lg-6 my-3">*/}
      {/*        <div className="contct-form">*/}
      {/*          <div className="sm-title">*/}
      {/*            <h4 className="font-alt">Contact us</h4>*/}
      {/*          </div>*/}
      {/*          /!* End .sm-title *!/*/}
      {/*          <Contact />*/}
      {/*        </div>*/}
      {/*      </div>*/}
      {/*      /!* End .col *!/*/}

      {/*      <div className="col-lg-4 ml-auto my-3">*/}
      {/*        <div className="contact-info">*/}
      {/*          <div*/}
      {/*            className="sm-title"*/}
      {/*            data-aos="fade-up"*/}
      {/*            data-aos-duration="1200"*/}
      {/*          >*/}
      {/*            <h4 className="font-alt">Get in touch</h4>*/}
      {/*            <p>*/}
      {/*              Always available for freelancing if the right project comes*/}
      {/*              along, Feel free to contact me.*/}
      {/*            </p>*/}
      {/*          </div>*/}

      {/*          <div*/}
      {/*            className="media"*/}
      {/*            data-aos="fade-up"*/}
      {/*            data-aos-duration="1200"*/}
      {/*            data-aos-delay="50"*/}
      {/*          >*/}
      {/*            <div className="icon">*/}
      {/*              <i className="ti-map"></i>*/}
      {/*            </div>*/}
      {/*            <span className="media-body">*/}
      {/*              123 Stree New York City , <br />*/}
      {/*              United States Of America <br />*/}
      {/*              NY 750065.*/}
      {/*            </span>*/}
      {/*          </div>*/}
      {/*          /!* End media *!/*/}

      {/*          <div*/}
      {/*            className="media"*/}
      {/*            data-aos="fade-up"*/}
      {/*            data-aos-duration="1200"*/}
      {/*            data-aos-delay="100"*/}
      {/*          >*/}
      {/*            <div className="icon">*/}
      {/*              <i className="ti-email"></i>*/}
      {/*            </div>*/}
      {/*            <span className="media-body">*/}
      {/*              support@domain.com*/}
      {/*              <br />*/}
      {/*              info@domain.com*/}
      {/*            </span>*/}
      {/*          </div>*/}
      {/*          /!* End media *!/*/}

      {/*          <div*/}
      {/*            className="media"*/}
      {/*            data-aos="fade-up"*/}
      {/*            data-aos-duration="1200"*/}
      {/*            data-aos-delay="150"*/}
      {/*          >*/}
      {/*            <div className="icon">*/}
      {/*              <i className="ti-mobile"></i>*/}
      {/*            </div>*/}
      {/*            <span className="media-body">*/}
      {/*              +044 966 9696 636*/}
      {/*              <br />*/}
      {/*              +044 966 9696 636*/}
      {/*            </span>*/}
      {/*          </div>*/}
      {/*          /!* End media *!/*/}
      {/*        </div>*/}
      {/*      </div>*/}
      {/*      /!* End .col *!/*/}
      {/*    </div>*/}
      {/*  </div>*/}
      {/*</section>*/}
      {/*/!* End Contact *!/*/}

      {/* Footer */}
      <footer className="footer white">
        <div className="container">
          <Footer />
        </div>
      </footer>
      {/*  End Footer */}
    </>
  );
};

export default HomeThree;
